<div class="container" id="page-title">
  <div class="row w-100">
    <div class="col-md">
      <h1>{{ pageTitle || 'Início' }}</h1>
    </div>
    <div class="col-auto">
      <div class="d-flex align-items-center flex-wrap">
        <a class="breadcrumb-item" routerLink="/">Início</a>
        <ng-container *ngFor="let item of menuItems; let i = index">
          <em class="fa fa-chevron-right fa-sm mx-2 text-gray"></em>
          <a class="breadcrumb-item" [routerLink]="item.url"> {{ item.label }} </a>
        </ng-container>
      </div>
    </div>
  </div>
</div>
