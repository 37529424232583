import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.scss']
})
export class CalendarioComponent implements OnInit {

  @Input() month: number = 0;
  @Input() year: number = 0;
  @Input() selectedDays: Date[] = [];
  @Input() dateSelected;
  @Input() checkRegistroAula: boolean;
  @Input() showTooltip: boolean = false;
  @Input() tooltipValue;
  @Output() onClick = new EventEmitter();


  dataAtual: Date = new Date();
  diasCalendario: Date[] = [];

  ngOnInit() {
    this.construirCalendario();
  }

  construirCalendario() {
    const ano = this.dataAtual.getFullYear();
    const mes = this.dataAtual.getMonth();

    const primeiroDiaDaSemana = 0; // domingo
    const ultimoDiaDaSemana = 6; // sábado

    // Vai subtraindo -1 até chegarmos no primeiro dia da semana
    const dataInicial = new Date(ano, mes, 1);
    while (dataInicial.getDay() !== primeiroDiaDaSemana) {
      dataInicial.setDate(dataInicial.getDate() - 1);
    }

    // Vai somando +1 até chegarmos no último dia da semana
    const dataFinal = new Date(ano, mes + 1, 0);
    while (dataFinal.getDay() !== ultimoDiaDaSemana) {
      dataFinal.setDate(dataFinal.getDate() + 1);
    }

    this.diasCalendario = [];
    for (
      const data = new Date(dataInicial.getTime());
      data <= dataFinal;
      data.setDate(data.getDate() + 1)
    ) {
      this.diasCalendario.push(new Date(data.getTime()));
    }
  }

  alterarMes(offsetMes: number) {
    this.dataAtual.setMonth(this.dataAtual.getMonth() + offsetMes);
    this.dataAtual = new Date(this.dataAtual.getTime());
    this.construirCalendario();
  }

  public get daysOfCalendar() {
    const primeiroDiaDaSemana = 0; // domingo
    const ultimoDiaDaSemana = 6; // sábado

    // Vai subtraindo -1 até chegarmos no primeiro dia da semana
    const dataInicial = new Date(this.year, this.month, 1);
    while (dataInicial.getDay() !== primeiroDiaDaSemana) {
      dataInicial.setDate(dataInicial.getDate() - 1);
    }

    // Vai somando +1 até chegarmos no último dia da semana
    const dataFinal = new Date(this.year, this.month + 1, 0);
    while (dataFinal.getDay() !== ultimoDiaDaSemana) {
      dataFinal.setDate(dataFinal.getDate() + 1);
    }

    const daysOfCalendar = [];
    for (
      const data = new Date(dataInicial.getTime());
      data <= dataFinal;
      data.setDate(data.getDate() + 1)
    ) {
      daysOfCalendar.push(new Date(data.getTime()));
    }
    return daysOfCalendar;
  }

  public hasValue(currentDate: Date): boolean {
    return this.selectedDays.some(day => day.getTime() === currentDate.getTime());
  }

  public dateClicked(date) {
    return date.getTime() === this.dateSelected?.getTime();
  }

  public canView(dia) {
    return dia.getMonth() === this.month;
  }

  public getTooltipValue(dia: Date){
    if(this.showTooltip && this.tooltipValue.length > 0){
      const tooltip = this.tooltipValue.find(tooltip => {
        const tooltipDay = tooltip.dia;
        const day = dia.getDate();
        return tooltipDay === day;
      });
      return tooltip ? tooltip.tooltipMsg : null;
    } else {
      return null;
    }
  }

  public onClickEmit(dia){
    if(this.canView(dia)){
      this.onClick.emit(dia);
    }
  }

}

