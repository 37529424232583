interface MenuType {
  url?: string;
  label: string;
  exact?: boolean;
}

export const MENUS_INFOBAR: MenuType[] = [
  {
    url: '',
    label: 'Home',
  },

];
