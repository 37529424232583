import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GenericChartData } from '../chart-types';
import { ColorsPizzaGraphArray } from '../colors-graph';

@Component({
  selector: 'app-advanced-pie-chart',
  templateUrl: './advanced-pie-chart.component.html',
  styleUrls: ['./advanced-pie-chart.component.scss']
})
export class AdvancedPieChartComponent implements AfterViewInit {

  @Input() data: GenericChartData[] = [];
  @Input() view: any[];
  @Input() label: string;
  colorScheme = {
    domain: [...ColorsPizzaGraphArray]
  };

  @Input() buttons: boolean = false;
  @Input() isPercentage: boolean = false;
  @Output() buttonClicked = new EventEmitter<string>();
  @ViewChild('chart') chart; 

  constructor() {
    Object.assign( this.data );
  }

  ngAfterViewInit() {
    this.onResize();
  }

  onResize(event?: any) {
    const width = window.screen.width < 720 ? window.screen.width : this.width;
    const height = this.view ? this.view[1] : 320;
    this.view = [width, height];
  }

  get width(): number {
    const width = window.screen.width < 720 ? window.screen.width : window.screen.width * 0.4 - 65;
    return this.chart?.nativeElement?.offsetWidth || width;
  }

  get dataIsEmpty(): boolean {
    return !this.data || this.data?.length === 0;
  }

  percentFormat(value) {
    return Math.round(value);
  }

  onClick(value: any) {
    this.buttonClicked.emit(value.textContent.toLowerCase().trim());
  }

  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
