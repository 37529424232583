interface MenuType {
  url?: string;
  label: string;
  exact?: boolean;
}

export const MENUS_NAVBAR: MenuType[] = [
  {
    url: '',
    label: 'Início',
    exact: true
  },
  {
    url: 'meus-dados',
    label: 'Meus Dados',
    exact: true
  },
  // {
  //   url: '/configuracao',
  //   label: 'Configurações',
  // },
  {
    url: 'diario',
    label: 'Diário'
  }
];
