
<div class="card">
  <div class="card-body">
    <div>
      <div class="row">
        <ng-content select="[button-top]"></ng-content>
        <div class="label"><h5><strong>{{label}}</strong></h5></div>
      </div>
      <div *ngIf="!dataIsEmpty" id="chart" class="d-flex justify-content-center">
        <ngx-charts-bar-vertical
          [view]="view"
          [scheme]="colorScheme"
          [results]="data"
          [gradient]="gradient"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [animations]="false"
          [legend]="showLegend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [roundEdges]="false"
          (select)="onSelect($event)">
        </ngx-charts-bar-vertical>
      </div>
    </div>
    <div class="row container-fluid mt-4" style="height: 240px;" *ngIf="dataIsEmpty">
      <p>
        Não há dados a serem exibidos.
      </p>
    </div>
  </div>
</div>
