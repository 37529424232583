<div [formGroup]="form" class="justify-to-content">
  <p-inputSwitch
    [disabled]="disabled"
    [formControlName]="name"
    [inputId]="name"
    class="custom-input-switch"
    [ngClass]="customClass"
    (onChange)="onChange.emit($event)"
  >
  </p-inputSwitch>
</div>
