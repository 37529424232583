
<div class="card">
  <div class="card-body">
    <div>
      <div class="row">
        <ng-content select="[button-top]"></ng-content>
        <div #ref class="col-8 d-flex flex-column justify-content-center">
          <ng-content select="[content]" class="d-flex"></ng-content>
        </div>
        <div class="col label text-center"><h5><strong>{{label}}</strong></h5>
          <div *ngIf="showChartWithMessage" id="chart" class="d-flex justify-content-center mt-3">
            <ngx-charts-bar-vertical
              [view]="view"
              [scheme]="colorScheme"
              [results]="data"
              [gradient]="gradient"
              [xAxis]="showXAxis"
              [yAxis]="showYAxis"
              [yAxisTicks]="yAxisTicks"
              [animations]="false"
              [legend]="showLegend"
              [showXAxisLabel]="showXAxisLabel"
              [showYAxisLabel]="showYAxisLabel"
              [xAxisLabel]="xAxisLabel"
              [yAxisLabel]="yAxisLabel"
              [roundEdges]="false"
              (select)="onSelect($event)">
            </ngx-charts-bar-vertical>
          </div>
          <div class="row container-fluid mt-4" style="height: 240px;" *ngIf="!showChartWithMessage">
            <p>
              Não há dados a serem exibidos.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
