<ng-container [formGroup]="form">
  <div class="d-flex">
    <label
      class="radio-custom-container mr-2 flex-none"
      *ngFor="let item of options"
    >
      <input
        [id]="'option-' + item.value"
        type="radio"
        class="mr-2"
        [value]="item.value"
        [formControlName]="name"
      />
      <label [for]="'option-' + item.value" class="d-inline">{{
        item.name
      }}</label>
      <span class="radiomark"></span>
    </label>
  </div>
</ng-container>
