import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutocompleteDto } from '../dto/auto-complete-dto';

import { URL_CONFIG } from '../utils/url-configs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class AutocompleteService {

  constructor(private apiService: ApiService) {}

  public getForSelectFromObject(
    url: string,
    obj?: {}
  ): Observable<any> {
    return this.apiService
      .getForSelect(
        `${URL_CONFIG.url_autocomplete}/${url}`,
        this.apiService.getQueryParamsForFilter(obj)
      )
      .pipe(
        map((response) =>
          response.body.data
        )
      );
  }

  public getForSelect(
    url: string,
    params?: URLSearchParams
  ): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(
      `${URL_CONFIG.url_autocomplete}/${url}`,
      params
    );
  }

  public getPerfisForSelect(
    params?: URLSearchParams
  ): Observable<AutocompleteDto[]> {
    return this.apiService
      .getForSelect(`${URL_CONFIG.url_autocomplete}/perfil`, params)
      .pipe(
        map((response) =>
          response.body.data.map((data) => new AutocompleteDto(data))
        )
      );
  }

  public getPerfilForSelect(
    params?: URLSearchParams
  ): Observable<AutocompleteDto[]> {
    return this.apiService
      .getForSelect(`${URL_CONFIG.url_autocomplete}/perfil`, params)
      .pipe(
        map((response) =>
          response.body.data.map((data) => new AutocompleteDto(data))
        )
      );
  }

  public getComponenteCurriculareForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/componente-curricular`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getCompetenciaUnidadeTematicaForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/competencia-unidade-tematica`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getMetodologiaForSelect(params?: URLSearchParams): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/metodologia`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getHabilidadeUnidadeTematicaForSelect( params?: URLSearchParams ): Observable<AutocompleteDto[]> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/habilidade-unidade-tematica`, params)
      .pipe(map(response => response.body.data.map(data => new AutocompleteDto(data))));
  }

  public getDiarioClasseRegistroAulaTempo( params?: URLSearchParams ): Observable<any> {
    return this.apiService.getForSelect(`${URL_CONFIG.url_autocomplete}/diario-classe-registro-aula-tempo`, params)
      .pipe(map(response => response.body.data));
  }

}




