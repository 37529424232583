<p-calendar
  [locale]="locale"
  [id]="id || name"
  [disabled]="disabled"
  [ngClass]="customClass"
  [class.invalid]="invalid"
  [(ngModel)]="inputModel"
  [dateFormat]="dateFormat"
  [placeholder]="placeholder"
  [yearNavigator]="true"
  [yearRange]="yearRange"
  [showIcon]="true"
  [showButtonBar]="showButtonBar"
  [view]="view"
  [maxDate]="maxDate"
  [minDate]="minDate"
  (onClearClick)="change('')"
  (onSelect)="change($event)"
  (onBlur)="blur()"
  ></p-calendar>
