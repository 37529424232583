import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginacaoDto } from '@core/dto/paginacao-dto';
import { GenericListOptions } from '../generic-types';
import { FormBase } from './../../../utils/form-base';
import { GenericListColumn } from './../generic-types';
import { CustomActionsLeftDirective } from './directives/custom-actions-left.directive';
import { CustomColumnsDirective } from './directives/custom-columns.directive';


@Component({
  selector: 'app-generic-list',
  templateUrl: './generic-list.component.html',
  styleUrls: ['./generic-list.component.scss']
})
export class GenericListComponent extends FormBase implements OnInit {

  @ContentChild(CustomColumnsDirective, { static: true, read: TemplateRef }) customColumns;
  @ContentChild(CustomActionsLeftDirective, { static: true, read: TemplateRef }) customActionsLeft;

  @Input() page = new PaginacaoDto<any>();
  @Input() canSubmit: boolean = true;
  @Input() options: GenericListOptions<any>;
  @Input() items: any[] = [];
  @Input() registerButton: boolean = true;
  @Input() showFilter: boolean = true;
  @Input() backButton = false;


  @Input() customEditPath: string;
  @Input() customRegisterPath: string;
  @Input() customViewFunction: boolean = false;

  @Output() onChangePage = new EventEmitter<number>();
  @Output() onOrder = new EventEmitter<string>();
  @Output() onFilter = new EventEmitter<boolean>();
  @Output() onInactive = new EventEmitter<string>();
  @Output() onClearFilter = new EventEmitter<boolean>();
  @Output() onDelete = new EventEmitter<string>();
  @Output() onViewClick = new EventEmitter<string>();
  @Output() onClick = new EventEmitter<string>();
  @Output() onCheck = new EventEmitter<any>();

  constructor(public router: Router, public activedRoute: ActivatedRoute) {
    super(router, activedRoute)
  }

  ngOnInit(): void {
  }

  get table() {
    return this.options?.tableConfig;
  }

  get showActions() {
    return Object.values(this.table.actions).some(can => can)
  }

  get pathRegister() {
    return this.router.url + '/cadastrar/';
  }

  get pathEdit() {
    return this.customEditPath || this.router.url + '/editar/';
  }

  get pathView() {
    return this.customEditPath || this.router.url + '/visualizar/';
  }

  get totalColspan() {
    return this.table?.columns.length + (this.showActions ? 1 : 0)
  }

  getValueColumn(item: any, column: GenericListColumn<any>) {
    const value = item[column.attribute];
    if (column.formatter)
      return column.formatter(item);
    if (typeof value == 'boolean')
      return item[column.attribute] ? 'Sim' : 'Não';
    return item[column.attribute];

  }

  submit() {
    this.onFilter.emit();
  }

  clearFilter() {
    this.onClearFilter.emit(true)
  }

  inactive(item) {
    this.onInactive.emit(item)
  }

  changePage(page) {
    this.onChangePage.emit(page)
  }

  orderColumn(column) {
    this.onOrder.emit(column)
  }

  view(item) {
    if (this.customViewFunction) {
      this.onViewClick.emit(item);
    } else {
      this.router.navigateByUrl(this.pathView + item.id);
    }
  }

  edit(item) {
    this.router.navigateByUrl(this.pathEdit + item.id)
  }

  delete(item) {
    this.onDelete.emit(item)
  }

  lineClicked(item) {
    this.onClick.emit(item);
  }

  check(item, checked) {
    this.onCheck.emit({item, checked});
  }
}
