import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MethodEnum } from 'src/app/core/enums/method.enum';
import { ArquivoService } from 'src/app/core/services/arquivo.service';
import { FormBase } from '../../utils/form-base';

@Component({
  selector: 'app-upload-one-file',
  templateUrl: './upload-one-file.component.html',
  styleUrls: ['./upload-one-file.component.scss']
})
export class UploadOneFileComponent extends FormBase implements OnChanges{
  @Input() editable: boolean = true;
  @Input() inputName: string = 'file';
  @Input() accept = '.pdf';
  @Input() form: FormGroup = new FormGroup({});
  @Input() name = '';
  @Input() canDownload = true;
  @Input() mdButton = false;
  @Input() isEdit: boolean;
  @Output() isEditChanged = new EventEmitter();
  @Output() update = new EventEmitter();
  public file;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private arquivoService: ArquivoService) {
      super(router, activatedRoute);
      this.choosePageUpdateOrView();
    }

  ngOnChanges(): void {
    if (this.isEdit) {
      this.inputName = 'text';
    }
  }

 public onSelectFile(event: Event): void {
    if (!this.editable) {
      return;
    }
    this.file = (event.target as HTMLInputElement).files[0];
    this.form.controls[this.name].setValue(this.file);
    this.update.emit({data: this.file ? true : false, type: MethodEnum.CAD, key: this.name});
  }

  public clear(): void{
    this.isEdit = false;
    this.form.controls[this.name].setValue('');
    this.update.emit({data: this.isEdit, type: MethodEnum.EDIT, key: this.name});
    this.file = undefined;
    this.viewAnexo();
  }

  public downloadFile(base64: any , fileName: string): void {
    const downlodedFile = new Blob([base64], { type: 'application/pdf' });
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(downlodedFile);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  public downloadFromBack(arquivo: any): void {
    this.arquivoService.download(arquivo.id.toString()).subscribe(res => {
      this.downloadFile(res.body, arquivo.nomeOriginal || arquivo.nome);
    }, (err) => {});
  }

  public download(): void {
    const arquivo = this.form.controls[this.name].value;
    if (arquivo.id) {
      this.downloadFromBack(arquivo);
      return;
    }
    this.downloadFile(arquivo, arquivo.name);
  }

  public viewAnexo(): boolean {
    if (this.file || this.form.get(this.name)?.value) {
        return true;
    }
    return false;
  }
}
