import { EnumBase } from '../models/enum-base.model';

export class SimOuNao extends EnumBase {
    constructor() {
        super([
          { value: true, name: 'Sim' },
          { value: false, name: 'Não' },
        ]);
    }
}
