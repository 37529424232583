import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ArquivoService } from '@core/services/arquivo.service';
import { FormBase } from '../../utils/form-base';
import { SweetalertCustom } from '../../utils/sweetalert-custom';
import { StatusDataBase } from '@core/enums/status-dataBase.enum';
import { FileTypeEnum } from '@core/enums/file-type.enum';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent extends FormBase {

  @Input() form: FormGroup = new FormGroup({});
  @Input() name = '';
  @Input() btnUploadIconClass = 'fas fa-upload';
  @Input() btnDownloadIconClass = 'fas fa-download';
  @Input() btnRemoveIconClass = 'fas fa-times';
  @Input() maxFileSize = 10 * 1024 * 1024; //10MB
  @Input() canEdit: boolean = true;
  @Input() maxFiles: number;
  @Input() maxFileValidateMsg: {title: string; msg: string};
  @Output() update = new EventEmitter();
  @ViewChild('fileInput') inputRef!: any;
  @Input() accept = ['.pdf'];

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private arquivoService: ArquivoService
  ) {
    super(router, activatedRoute);
  }

  public trackByFn(index: number, item: any): number {
    return index;
  }

  public onSelectFile(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files?.length) {
      const file = inputElement.files[0];

      // Verifica o tipo do arquivo e o tamanho
      if (!this.accept.includes('.' + new FileTypeEnum().getName(file.type)) || file.size > this.maxFileSize) {
        SweetalertCustom.newShowAlertConfirm('warning', "Atenção!", "O Arquivo selecionado não atende aos critérios mínimos exigidos pela aplicação, por favor reveja as configurações e tente novamente!")
        return;
      }

      // Verifica se possui limite de arquivos
      const formValue = this.form.get(this.name).value;
      if(this.maxFiles){
        if(formValue.length >= this.maxFiles){
          SweetalertCustom.newShowAlertConfirm('warning', this.maxFileValidateMsg.title, this.maxFileValidateMsg.msg)
          return;
        }
      }

      // Se o arquivo passar nas verificações, processá-lo
      formValue.push(file);
      this.update.emit({data: true, type: StatusDataBase.CREATE, key: this.name, index: formValue.length - 1});
      this.inputRef.nativeElement.value = '';

    }
  }

  public remove(itemToRemove: any, index: number): void{
    SweetalertCustom.showAlertConfirmAndCancel('Atenção!', 'warning', 'Tem certeza que deseja Excluir o Anexo?', 'Sim', 'Não')
      .then((res) => {
        if (res.isConfirmed) {
          this.inputRef.nativeElement.value = '';
          this.form.get(this.name).setValue(
            this.form.get(this.name).value.filter(
              item => item != itemToRemove
            )
          );
          this.update.emit({data: false, type: StatusDataBase.UPDATE, key: this.name, index: index});
        }
      });
  }

  public download(item: any): void {
    const arquivo = item;
    if (arquivo.id) {
      this.downloadFromBack(arquivo);
      return;
    }
    let fileName = arquivo.name || arquivo.nome;
    const fileNameSplit = fileName.split('.');

    // Remove a extensão do arquivo se existir no array de fileNameSplit
    if (fileNameSplit.length > 1 && fileNameSplit[fileNameSplit.length - 1] === arquivo.extensao) {
        fileNameSplit.pop();
    }

    fileName = fileNameSplit.join('.');
    const blobFile = this.createBlobBase64(arquivo.base64);
    this.downloadBlobFile(blobFile, fileName, arquivo.extensao);
  }

  public createBlobBase64(base64: any, typeName = 'pdf'): Blob {
     // Decodificar a string base64 para um ArrayBuffer
    const byteString = atob(base64); // Remover cabeçalho da string base64, se houver
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: new FileTypeEnum().getValue(typeName) as string });
  }

  downloadBlobFile(blob: Blob, fileName: string, typeName = 'pdf'){
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = `${fileName}.${typeName}`;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
  public downloadFromBack(arquivo: any): void {
    this.arquivoService.download(arquivo.id.toString()).subscribe(res => {
      this.downloadBlobFile(res.body, arquivo.nomeOriginal || arquivo.nome, arquivo.extensao);
    }, (err) => {});
  }

  public viewAnexo(): boolean {
    if (this.form.get(this.name)?.value) {
        return true;
    }
    return false;
  }

  public formatFileName(item: any){
    let fileName = item.name || item.nome;
    const fileNameSplit = fileName.split('.');

    // Remove a extensão do arquivo se existir no array de fileNameSplit
    if (fileNameSplit.length === 1) {
        fileNameSplit.push(item.extensao);
    }

    fileName = fileNameSplit.join('.');
    return fileName;
  }


}
