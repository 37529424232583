<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <ng-content content></ng-content>
      <footer class="row">
        <div class="col-12 col-md-12">
          <ng-content buttons></ng-content>
          <div class="mb-1">
            <small *ngIf="isEdit"
              >Última atualização em {{ ultimaAtualizacao }}</small
            >
          </div>
          <button
            *ngIf="typePage != 'visualizar' && !isView"
            type="submit"
            (click)="submit()"
            title="Salvar"
            [disabled]="!canSubmit"
            class="btn rounded-pill btn-primary float-right mb-2 col-md-auto"
            id="btn-submit"
          >
            {{ pageId ? "Atualizar" : "Salvar" }}
          </button>

          <button
            *ngIf="!isView"
            (click)="cancel()"
            type="submit"
            title="Cancelar"
            class="btn rounded-pill btn-outline-primary float-left mb-2 col-md-auto"
            id="btn-cancelar"
          >
            Cancelar
          </button>
          <a [routerLink]="pathBack">
            <button
              *ngIf="isView"
              type="submit"
              title="Voltar"
              id="btn-voltar"
              class="btn rounded-pill btn-outline-primary float-left mb-2 col-md-auto"
            >
              Voltar
            </button>
          </a>
        </div>
      </footer>
    </div>
  </div>
</div>
