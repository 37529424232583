<ng-container [formGroup]="form">
  <div class="w-100">
    <!-- File input com display none  -->
    <div class="custom-file d-none">
      <input #fileInput
        class="custom-file-input"
        type="file"
        [accept]="accept"
        (change)="onSelectFile($event)"
      >
    </div>

    <!-- Btn de upload que dispara o file input -->
    <div class="d-flex flex-column align-items-center">
      <button
        type="button"
        class="btn rounded-pill btn-primary btn-sm w-fit-content mb-3"
        (click)="fileInput.click()"
        [disabled]="!canEdit"
        >
        <em class="{{ btnUploadIconClass }}"></em>
        Anexar Arquivo
      </button>
    </div>

    <div class="divChip w-100">
      <div class="d-flex flex-wrap" *ngIf="form.get(name).value.length > 0">
      <!-- Btn de download e remoção de arquivo -->
        <div *ngFor="let item of form.get(name).value; let i = index; trackBy: trackByFn"
          class="d-flex justify-content-center flex-wrap mx-3 my-2 options"
        >
          <span class="px-3 pt-2">{{formatFileName(item)}}</span>
          <!-- Download -->
          <button
            id="{{'downloadFile-' + i}}"
            title="Baixar o documento anexado"
            class="btn rounded-pill btn-default btn-sm"
            (click)="download(item)"
          >
            <em class="{{ btnDownloadIconClass }}"></em>
          </button>
          <!-- Remoção -->
          <button *ngIf="canEdit"
            class="btn rounded-pill btn-default btn-sm"
            title="Remover documento"
            (click)="remove(item, i)"
          >
            <em class="{{ btnRemoveIconClass }}"></em>
          </button>
        </div>
      </div>
    </div>

  </div>
</ng-container>
