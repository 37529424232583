<ng-container [formGroup]="form">
  <div class="div-chip"
    [ngClass]="customClass"
  >
    <ng-container *ngIf="listItems.length > 0">
      <div class="text-white ml-2 mr-2 div-custom" [id]="item.id" *ngFor="let item of listItems">
        <ng-container *ngIf="links">
          <a class="fst-italic cursor-pointer"
            [href]="formatUrl(item.nome || item.descricao)"
            target="_blank" rel="noopener noreferrer"
          >
            <em class="fas fa-link pr-2" id="iconCustom"></em>
            {{ item.nome || item.descricao }}
          </a>
        </ng-container>
        <ng-container *ngIf="!links">
          {{ item.nome || item.descricao }}
        </ng-container>
        <em *ngIf="canEdit" class="fas fa-times-circle pl-2 cursor-pointer" id="iconCustom" (click)="removeItem(item)"></em>
      </div>
    </ng-container>
  </div>
</ng-container>
