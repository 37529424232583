import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { AutoCompleteModule, BreadcrumbModule, CalendarModule, InputSwitchModule, TooltipModule } from 'primeng';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { CardComponent } from './card/card.component';
import { AlertCustomComponent } from './components/alert-custom/alert-custom.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { CalendarioComponent } from './components/calendario/calendario.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { FieldComponent } from './components/field/field.component';
import { FilterComponent } from './components/filter/filter.component';
import { FrequencyTableComponent } from './components/frequency-table/frequency-table.component';
import { AdvancedPieChartComponent } from './components/generic-charts/advanced-pie-chart/advanced-pie-chart.component';
import { PieChartComponent } from './components/generic-charts/pie-chart/pie-chart.component';
import { VerticalBarChartContentComponent } from './components/generic-charts/vertical-bar-chart-content/vertical-bar-chart-content.component';
import { VerticalBarChartComponent } from './components/generic-charts/vertical-bar-chart/vertical-bar-chart.component';
import { GenericFormComponent } from './components/generics-crud-components/generic-form/generic-form.component';
import { CustomColumnsDirective } from './components/generics-crud-components/generic-list/directives/custom-columns.directive';
import { GenericListComponent } from './components/generics-crud-components/generic-list/generic-list.component';
import { LoadingCustomInterceptor } from './components/loading-custom/config/loading-custom-interceptor';
import { LoadingCustomComponent } from './components/loading-custom/loading-custom.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { PaginatorCustomComponent } from './components/paginator-custom/paginator-custom.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { TableBodyDirective } from './components/table-custom/config/table-body.directive';
import { TableEmptyDirective } from './components/table-custom/config/table-empty.directive';
import { TableHeaderDirective } from './components/table-custom/config/table-header.directive';
import { TableCustomComponent } from './components/table-custom/table-custom.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { UploadOneFileV2Component } from './components/upload-one-file-v2/upload-one-file-v2.component';
import { UploadOneFileComponent } from './components/upload-one-file/upload-one-file.component';
import { UploadPhotoComponent } from './components/upload-photo/upload-photo.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { UpperCaseDirective } from './directives/upper-case.directive';
import { StatusPipe } from './pipes/status.pipe';
import { ListBoxComponent } from './components/list-box/list-box.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { PaginatorCustomDinamicComponent } from './components/paginator-custom-dinamic/paginator-custom-dinamic.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { CustomActionsLeftDirective } from './components/generics-crud-components/generic-list/directives/custom-actions-left.directive';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { InputSwitchComponent } from './components/input-switch/input-switch.component';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { DatePickerV2Component } from './components/date-picker-v2/date-picker-v2.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { PercentageFormatPipe } from './pipes/percentage-format.pipe';
import { DatePickerRangeComponent } from './components/date-picker-range/date-picker-range.component';

@NgModule({
  declarations: [
    AlertCustomComponent,
    LoadingCustomComponent,
    PaginatorCustomComponent,
    TableCustomComponent,
    TableHeaderDirective,
    TableBodyDirective,
    TableEmptyDirective,
    CustomColumnsDirective,
    UpperCaseDirective,
    OnlyNumberDirective,
    CardComponent,
    UploadOneFileComponent,
    PageTitleComponent,
    SearchInputComponent,
    FilterComponent,
    ToggleSwitchComponent,
    StatusPipe,
    AutocompleteComponent,
    DatePickerComponent,
    UploadPhotoComponent,
    DatePickerComponent,
    PieChartComponent,
    AdvancedPieChartComponent,
    UploadOneFileV2Component,
    GenericFormComponent,
    GenericListComponent,
    FrequencyTableComponent,
    CalendarioComponent,
    VerticalBarChartComponent,
    VerticalBarChartContentComponent,
    FieldComponent,
    ListBoxComponent,
    UploadFilesComponent,
    PaginatorCustomDinamicComponent,
    AccordionComponent,
    CustomActionsLeftDirective,
    RadioButtonComponent,
    InputSwitchComponent,
    MultiselectComponent,
    DatePickerV2Component,
    CheckboxComponent,
    PercentageFormatPipe,
    DatePickerRangeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxPaginationModule,
    BreadcrumbModule,
    NgxMaskModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBorderRadius: '0px',
      fullScreenBackdrop: true,
      backdropBackgroundColour: 'rgba(0,0,0,0.3)',
      primaryColour: '#007bff',
      secondaryColour: '#0069d9',
      tertiaryColour: '#007bff',
    }),
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    CalendarModule,
    CalendarModule,
    NgxChartsModule,
    InputSwitchModule,
    TooltipModule,
    MultiSelectModule,
    CheckboxModule
  ],
  exports: [
    AlertCustomComponent,
    LoadingCustomComponent,
    PaginatorCustomComponent,
    TableCustomComponent,
    TableHeaderDirective,
    TableBodyDirective,
    TableEmptyDirective,
    CustomColumnsDirective,
    UpperCaseDirective,
    OnlyNumberDirective,
    CardComponent,
    UploadOneFileComponent,
    PageTitleComponent,
    SearchInputComponent,
    FilterComponent,
    MultiSelectModule,
    ToggleSwitchComponent,
    CheckboxModule,
    StatusPipe,
    AutocompleteComponent,
    DatePickerComponent,
    UploadPhotoComponent,
    DatePickerComponent,
    PieChartComponent,
    AdvancedPieChartComponent,
    UploadOneFileV2Component,
    GenericFormComponent,
    GenericListComponent,
    FrequencyTableComponent,
    CalendarioComponent,
    VerticalBarChartComponent,
    VerticalBarChartContentComponent,
    FieldComponent,
    ListBoxComponent,
    UploadFilesComponent,
    AccordionComponent,
    CustomActionsLeftDirective,
    RadioButtonComponent,
    InputSwitchComponent,
    MultiselectComponent,
    DatePickerV2Component,
    CheckboxComponent,
    PercentageFormatPipe,
    DatePickerRangeComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingCustomInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
