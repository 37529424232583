import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PaginacaoDto } from '@core/dto/paginacao-dto';

@Component({
  selector: 'app-paginator-custom-dinamic',
  templateUrl: './paginator-custom-dinamic.component.html',
  styleUrls: ['./paginator-custom-dinamic.component.scss']
})
export class PaginatorCustomDinamicComponent {

  @ViewChild('p') p: any;
  @Input() public items: Array<any>;
  @Input() public itemsPerPage: number;
  @Input() public stylesClass: '';
  @Output() public pageChange: EventEmitter<number> = new EventEmitter();

  // teste nova paginação por demanda
  @Input() pageResponse: PaginacaoDto<any>;
  @Input() public currentPage: number;
  @Input() public pageCount: number;
  @Input() public total: number;
  public pages: Array<number> = new Array<number>();
  public count: number;

  @Output() public itemsPerPageChange = new EventEmitter<number>();
  public itemsPerPageOptions = [50, 100, 200];


 public getTotalPerPage(page) {
    let current: number;
    let total: number;
    let itemsPerPage: number;

    if (this.pageResponse) {
      current = this.pageResponse?.currentPage ?? 0;
      total = this.pageResponse?.total ?? 0;
      itemsPerPage = this.pageResponse?.numPerPage ?? 0;
    } else if (page) {
      current = page.getCurrent();
      total = page.getTotalItems();
      itemsPerPage = this.itemsPerPage;
    }

    const start = (current - 1) * itemsPerPage + 1;
    const end = Math.min(start + itemsPerPage - 1, total);
    return end || '0';
  }

  public changeEvent(page): void {
    this.pageChange.emit(page);
  }

  public getTotalItems(): number {
    return this.pageResponse ? this.pageResponse.total : this.items.length ;
  }

  public onItemsPerPageChange() {
    this.itemsPerPageChange.emit(this.itemsPerPage);
  }
}
