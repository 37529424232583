export class ListBoxDto {
 public descricao: string;
 public nome?: string;
 public id?: string;

  constructor(object?: any) {
    if (typeof object === 'object' && !Array.isArray(object)) {
      this.nome = object.nome;
      this.id = object.id;
      this.descricao = object.descricao;
    } else{
      this.nome = object;
      this.id = null;
      this.descricao = object;
    }
  }
}
