import { Component, Input } from '@angular/core';
import { GenericChartData } from '../chart-types';
import { ColorsGraphArray } from '../colors-graph';

@Component({
  selector: 'app-vertical-bar-chart',
  templateUrl: './vertical-bar-chart.component.html',
  styleUrls: ['./vertical-bar-chart.component.scss']
})
export class VerticalBarChartComponent {

  @Input() data: GenericChartData[] = [];
  @Input() label: string = '';

  @Input() view: any[] = [200, 220];

  @Input() showXAxis = true;
  @Input() showYAxis = false;
  @Input() gradient = false;
  @Input() showLegend = false;
  @Input() showXAxisLabel = false;
  @Input() xAxisLabel = '';
  @Input() showYAxisLabel = false;
  @Input() yAxisLabel = '';

  colorScheme = {
    domain: [...ColorsGraphArray]
  };

  constructor() {
    Object.assign(this.data);
  }

  get dataIsEmpty(): boolean {
    return this.data?.every((x) => x.value === 0);
    // return !this.data || this.data?.length === 0;
  }

  onSelect(event) { }

}
