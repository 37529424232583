import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { StorageService } from '@core/services/storage.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const NAMES_PROJECT  = environment.namespaceProject;

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private storageService: StorageService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // Verificar se o usuário está logado na aplicação
    const token = this.storageService.getFromLocalStorage('token');

    if (token === null) {
      this.router.navigate(['/auth/']);
      return false;
    }

    return true;
  }
}
