import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SimOuNao } from 'src/app/core/enums/sim-ou-nao.enum';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnInit {

  @Input() options = new SimOuNao().getAll();
  @Input() name = '';
  @Input() label = '';
  @Input() form: FormGroup;

  constructor() { }

  ngOnInit() {
  }

}
