import { Component, Input } from '@angular/core';
import { GenericChartData } from '../chart-types';
import { ColorsGraphArray } from '../colors-graph';

@Component({
  selector: 'app-vertical-bar-chart-content',
  templateUrl: './vertical-bar-chart-content.component.html',
  styleUrls: ['./vertical-bar-chart-content.component.scss']
})
export class VerticalBarChartContentComponent {

  @Input() data: GenericChartData[] = [];
  @Input() label: string = '';

  @Input() view: any[] = [200, 220];
  @Input() yAxisTicks?: number[];
  @Input() showXAxis = true;
  @Input() showYAxis = false;
  @Input() gradient = false;
  @Input() showLegend = false;
  @Input() showXAxisLabel = false;
  @Input() xAxisLabel = '';
  @Input() showYAxisLabel = false;
  @Input() yAxisLabel = '';
  @Input() roundEdges = false;
  @Input() showMessageDataIsEmpty = true;
  @Input() colorScheme = {
    domain: [...ColorsGraphArray]
  };

  constructor() {
    Object.assign(this.data);
  }

  get dataIsEmpty(): boolean {
    return this.data?.every((x) => x.value === 0);
    // return  !this.data || this.data?.length === 0;
  }

  get showChartWithMessage(){
    if(!this.showMessageDataIsEmpty){
      return true;
    }
    if(this.dataIsEmpty){
      return false
    }
    return true;
  }

  onSelect(event) { }

}
