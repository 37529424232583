<ng-container [formGroup]="form">
  <p-multiSelect
    #multiselect
    [id]="id || name"
    [disabled]="disabled"
    [options]="optionsForAutocomplete"
    (onPanelShow)="getOptions($event)"
    [dataKey]="key"
    [optionLabel]="fieldName"
    defaultLabel="Selecione"
    [maxSelectedLabels]="maxSelectedLabels"
    [showToggleAll]="showToggleAll"
    [virtualScroll]="virtualScroll"
    selectedItemsLabel="{0} {{selectedItemsLabel}}"
    (onSelect)="onChange.emit($event)"
    [ngClass]="customClass"
    (focusout)="onFocusOut.emit($event)"
    (onKeyUp)="onKeyUp($event)"
    [style]="{ display: 'grid' }"
    [formControlName]="name"
    emptyFilterMessage="Nenhum resultado foi encontrado"
  >
  </p-multiSelect>
</ng-container>
