import { EnumBaseType } from 'src/app/shared/types/enum-base.type';

export class EnumBase {

    private enum: EnumBaseType[];

    constructor(enumParam: EnumBaseType[]) {
        this.enum = enumParam;
    }

    getAll(): EnumBaseType[] {
        return this.enum;
    }

    getAllForSelect(): EnumBaseType[] {
        const list = [...this.enum];
        list.unshift(({ name: 'Selecione...', value: '' }));
        return list;
    }

    getById(value: string): EnumBaseType {
        return this.enum.find((item: EnumBaseType) => item.value === value);
    }

    getValue(name: string) {
        const result = this.enum.find((item: EnumBaseType) => item.name === name);
        return result ? result.value : null;
    }

    getName(value: string) {
        const result = this.enum.find((item: EnumBaseType) => item.value === value);
        return result ? result.name : null;
    }

}
