<div [ngClass]="customClass">
  <div class="box text-bold" [ngClass]="theme" (click)="click()">
    {{title}} 
    <div *ngIf="state; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock><em class="fas fa-sort-up"></em></ng-template>
    <ng-template #elseBlock><em class="fas fa-sort-down"></em></ng-template>
  </div>
  
  <ng-container *ngIf="state">
    <ng-content select="[content]"></ng-content>
  </ng-container>
</div>
