import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { ControlAccessGuard } from './guards/control-access.guard';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { PaginaNaoEncontradaComponent } from './layouts/componentes/pagina-nao-encontrada/pagina-nao-encontrada.component';
import { PaginaSemAutorizacaoComponent } from './layouts/componentes/pagina-sem-autorizacao/pagina-sem-autorizacao.component';
import { FooterAuthComponent } from './layouts/layout-auth/components/footer-auth/footer-auth.component';
import { NavbarAuthComponent } from './layouts/layout-auth/components/navbar-auth/navbar-auth.component';
import { SidebarAuthComponent } from './layouts/layout-auth/components/sidebar-auth/sidebar-auth.component';
import { LayoutAuthComponent } from './layouts/layout-auth/layout-auth.component';
import { LayoutNoAuthComponent } from './layouts/layout-no-auth/layout-no-auth.component';
import { FooterComponent } from './layouts/componentes/footer/footer.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    LayoutAuthComponent,
    LayoutNoAuthComponent,
    SidebarAuthComponent,
    NavbarAuthComponent,
    FooterAuthComponent,
    PaginaSemAutorizacaoComponent,
    PaginaNaoEncontradaComponent,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    LayoutAuthComponent,
    LayoutNoAuthComponent,
    SidebarAuthComponent,
    NavbarAuthComponent,
    FooterAuthComponent,
    PaginaSemAutorizacaoComponent,
    PaginaNaoEncontradaComponent
  ],
  providers: [
    AuthGuard,
    ControlAccessGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
      providers: [],
    };
  }
}
