<div class="card">
  <div class="card-body" *ngIf="!dataIsEmpty">
    <div class="row">
      <div #ref class="col-12 col-md-7 d-flex flex-column justify-content-center">
        <ng-content select="[content]" class="d-flex"></ng-content>
      </div>
      <div [ngClass]="ref.children.length == 0 ? 'col-12' : 'col-md-5'">
        <div #chart class="chart">
          <ngx-charts-advanced-pie-chart (window:load)="onResize($event)" (window:resize)="onResize($event)" [view]="view" [results]="data" [label]="label" [animations]="true"
            [scheme]="colorScheme" [percentageFormatting]="percentFormat" (select)="onSelect($event)"
            (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
          </ngx-charts-advanced-pie-chart>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end" *ngIf="buttons">
      <ng-content select="[buttons]"></ng-content>
      <button type="submit" title="Pesquisar"
        class="btn btn-circle btn-xl rounded-pill btn-primary float-right mb-2 col-md-auto"
        (click)="onClick($event.target)">
        <em class="fa fa-search"></em>
      </button>
    </div>
  </div>
  <div class="row container-fluid ml-2 mt-4" style="height: 240px;" *ngIf="dataIsEmpty">
    <p>
      Não há dados a serem exibidos.
    </p>
  </div>
</div>