<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-3">
      <ng-content select="[top-buttons]"></ng-content>
    </div>
    <div class="col-12 col-md-6 d-flex justify-content-center">
      <div class="calendario-dias">
        <ng-container *ngFor="let day of daysOfCalendar; let i = index">
          <div *ngIf="i < 7">
            <strong>{{ day | date: "EEEEE":"":"pt-BR"}}</strong>
          </div>
        </ng-container>
        <div
          *ngFor="let day of daysOfCalendar; let i = index"
          (click)="onClickEmit(day)"
        >
          <div *ngIf="canView(day)" class="calendario-dia"
          [class.registrado]="hasValue(day)"
          [class.calendario-dia-selected]="dateClicked(day)"
          [pTooltip]="getTooltipValue(day)"
          [tooltipPosition]="showTooltip ? 'bottom' : null"
          [escape]="false"
          >
            {{ day | date: "dd" }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="container" [hidden]="checkRegistroAula">
        <div class="legenda d-flex flex-column align-items-baseline">
          <h6 class="mt-3 mb-4">Legendas</h6>
          <div class="legenda-item">
            <div class="calendario-dia registrado">
            </div>
            <h6>Registrado</h6>
          </div>
          <div class="legenda-item">
            <div class="calendario-dia ">
            </div>
            <h6>Não Registrado</h6>
          </div>
        </div>
      </div>
      <div class="col-12 mt-5">
        <ng-content select="[buttons]"></ng-content>
      </div>
    </div>
  </div>
</div>

