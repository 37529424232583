import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ArquivoService {
  private controller = 'arquivo';
  public baseUrl = environment.apiUrl;
  public namespaceProject = environment.namespaceProject;

  constructor(private httpService: HttpClient, private storageService: StorageService) { }

  headerFileOptions(): any {
    // tratar caso tenha o token
    const TOKEN = this.storageService.getFromLocalStorage('token') ?
     'Bearer ' + this.storageService.getFromLocalStorage('token') : '';

    return {
      headers: new HttpHeaders({
        Authorization: TOKEN,
      }),
      responseType: 'blob',
      observe: 'response',
    };
  }

  download(id: string): Observable<any> {
    return this.httpService.get(`${this.baseUrl}${this.controller}/${id}`,
      this.headerFileOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }
}
