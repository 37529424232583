<div class="form-group">
  <label *ngIf="label" [id]="id" [for]="name" [ngClass]="{
    'text-red': validateMessage?.messageDisplay[formToValidate] || isInvalid
  }"> {{ label }}
    <em *ngIf="tooltipTitle" class="fas fa-exclamation-circle text-danger" data-toggle="tooltip" data-placement="auto"
      [title]="tooltipTitle" onmouseenter="$(this).tooltip('show')">
    </em>
  </label>

  <div #content>
    <ng-content></ng-content>
  </div>

  <small class="form-text required" *ngIf="required && !validateMessage?.messageDisplay[formToValidate] && !isInvalid">
    *obrigatório
  </small>

  <small class="form-text text-red" *ngIf="validateMessage?.messageDisplay[formToValidate]"
    [innerHTML]="customMessage || validateMessage?.messageDisplay[formToValidate]">
  </small>
</div>