import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-switch',
  templateUrl: './input-switch.component.html',
  styleUrls: ['./input-switch.component.scss']
})
export class InputSwitchComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() disabled: boolean = false;
  @Input() name: string = '';
  @Input() customClass: any = '';

  @Output() onChange = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

}
