<div class="bg-white">
  <div class="table-responsive">
    <table aria-describedby="tabela frequência por mês" class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Mês/Dias</th>
          <th class="dia" scope="col" *ngFor="let item of dias"> {{item | mask:"00"}} </th>
          <th scope="col"> Total </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let mes of meses">
          <th class="mes" scope="row"> {{mes}}</th>
          <td *ngFor="let dia of dias">
            <div class="presenca" [ngClass]="presenca(mes, dia)">
            </div>
          </td>
          <td>
            <div class="total">
              {{totalDeFaltas(mes) || 0}}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  
  <div class="container">
    <div class="legenda d-flex flex-column align-items-baseline">
      <h6 class="mt-3 mb-4">Legendas</h6>
      <div class="legenda-item">
        <div class="presenca presente">
        </div>
        <h6>Presente</h6>
      </div>
      <div class="legenda-item">
        <div class="presenca ausente">
        </div>
        <h6>Ausente</h6>
      </div>
      <div class="legenda-item">
        <div class="presenca ausente-justificado">
        </div>
        <h6>Ausente Justificado</h6>
      </div>
      <div class="legenda-item">
        <div class="presenca">
        </div>
        <h6>Não Informado</h6>
      </div>
    </div>
   
  </div>
</div>