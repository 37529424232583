<footer>
  <div class="container h-100">
    <div class="row justify-content-between align-items-center h-100 w-100">
      <span>{{ anoAtual }} | Governo da Paraíba</span>

      <div class="m-auto" *ngIf="ambienteQA">
        <h6 class="info-teste">
          {{usuarioSession.nome?.split(' ')[0]}}, você está no ambiente de HOMOLOGAÇÃO/TREINAMENTO.
        </h6>
      </div>

      <a routerLink="/">
        <img src="assets/img/siage_Logo_negative.png" alt="logo siage" class="logo" />
      </a>

    </div>
  </div>
</footer>
