import { Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { ControlAccessGuard } from '@core/guards/control-access.guard';
import { LayoutAuthComponent } from './core/layouts/layout-auth/layout-auth.component';
import { LayoutNoAuthComponent } from './core/layouts/layout-no-auth/layout-no-auth.component';

export const APP_ROUTES: Routes = [
  {
    path: 'auth',
    component: LayoutNoAuthComponent,
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
    data: { breadcrumb: null },
  },
  {
    path: '',
    component: LayoutAuthComponent,
    data: { breadcrumb: null },
    canActivate: [AuthGuard, ControlAccessGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
        data: { breadcrumb: null },
      },
      {
        path: 'configuracao',
        loadChildren: () =>
          import('./modules/configuracao/configuracao.module').then(
            (m) => m.ConfiguracaoModule
          ),
        data: { breadcrumb: 'Configurações' },
      },
      {
        path: 'meus-dados',
        loadChildren: () =>
          import('./modules/meus-dados/meus-dados.module').then(
            (m) => m.MeusDadosModule
          ),
        data: { breadcrumb: 'Atualização de Informações Pessoais' },
      },
      {
        path: 'diario',
        loadChildren: () =>
          import('./modules/diario/diario.module').then(
            (m) => m.DiarioModule
          ),
        data: { breadcrumb: 'Diário'}
      }
    ],
  },
];
