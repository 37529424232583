import { Guid } from 'guid-typescript';

export class ArquivoCreateDto {
  public id: Guid;
  public base64: string;
  public nome: string;
  public extensao: string;
  public acao: string;

  constructor(object?: any) {
    if (object) {
      this.id = object.id;
      this.base64 = object.base64;
      this.nome = object.nome;
      this.extensao = object.extensao;
      this.acao = object.id ? 'EDIT' : 'CAD';
    }
  }
}
