import { EnumBase } from '../models/enum-base.model';

export class FileTypeEnum extends EnumBase {
    constructor() {
        super([
          { value: 'image/jpg', name: 'jpg' },
          { value: 'image/jpeg', name: 'jpeg'},
          { value: 'application/pdf', name: 'pdf' },
          { value: 'application/msword', name: 'doc' },
          { value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', name: 'docx' },
          { value: 'application/vnd.ms-powerpoint', name: 'ppt' },
          { value: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', name: 'pptx' }
        ]);
    }
}
