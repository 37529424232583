import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentageFormatPipe'
})
export class PercentageFormatPipe implements PipeTransform {

  transform(value: any): string {
    return (value === null || value === undefined) ? '-' : value + '%'
  }

}
